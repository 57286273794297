@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Mono:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700;800;900&display=swap');

:root {
    --fq-primary-hue: 215;
    --fq-primary-saturation: 100%;
    --fq-primary-luminance: 50%;
    --fq-primary-alpha: 1;
    --fq-primary-color: hsl(
        var(--fq-primary-hue),
        var(--fq-primary-saturation),
        var(--fq-primary-luminance),
        var(--fq-primary-alpha)
    );
    --fq-secondary-color: #f0a200;
    --fq-primary-text-color: #132f4c;
    --fq-secondary-text-color: rgb(71, 71, 71);
    --fq-text-color-3: rgb(96, 96, 96);
    --fq-text-color-opposite-1: #fff;
    --fq-header-max-width: 1300px;
    --fq-header-max-height: 70px;
    --fq-primary-background: #fff;
    --fq-box-shadow-1: 0 0 5px 1px rgba(0, 0, 0, 0.1);
    --fq-box-shadow-2: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
        rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    --fq-font-family: 'Rubik', sans-serif;
    --fq-font-family: 'Nunito', sans-serif;
    --fq-mono-font-family: 'Noto Sans Mono', monospace;
    --fq-border-color-1: rgba(0, 0, 0, 0.1);
    --fq-border-color-2: rgba(0, 0, 0, 0.2);
    --fq-border-color-3: rgba(0, 0, 0, 0.4);
    --fq-hover-color-1: rgba(0, 0, 0, 0.04);
    --fq-border-radius-1: 10px;
    --fq-background-color-1: #ffffff;
    --fq-background-color-2: #f3f3f3;
    --fq-background-color-5: hsl(var(--fq-primary-hue), var(--fq-primary-saturation), 100%, 1);
    --fq-z-index-minimum: -1;
    --fq-z-index-medium: 1;
    --fq-z-index-maximum: 100;
    --fq-z-index-super-maximum: 1000;
    --fq-disabled: rgba(0, 0, 0, 0.38);
    --fq-monospace-primary-font-color: #a42d2d;
    --fq-drawer-width: 280px;
    --fq-header-max-width: 1200px;
    --fq-header-color: rgba(129, 129, 129, 0.76);
    --fq-warning-red: rgb(255, 40, 40);
    --fq-warning-orange: #f0a538;
    --fq-text-placeholder-color: rgba(31, 31, 31, 0.137);
    --fq-fetch-note-input-background: var(--fq-background-color-1);
    --fq-color-text-background: linear-gradient(to right, #006aff, #004a95);
    --fq-disabled-color-button: black;
    --fq-disabled-background-button: black;
    --fq-disabled-border-button: black;
    transition: 0.3s;
}

*,
*:before,
*:after {
    box-sizing: border-box;
}

html,
body,
#root {
    margin: 0;
    padding: 0;
    font-family: var(--fq-font-family);
    height: 100%;
    overflow-y: overlay;
    transition: 0.3s;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.non-styled-link {
    text-decoration: none;
    color: inherit;
}

.colored-text {
    background: var(--fq-color-text-background);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.width-adjust {
    max-width: var(--fq-header-max-width);
    margin: auto;
}

a {
    color: var(--fq-primary-color);
}
