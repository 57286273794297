.blink_me {
    animation: blinker 2s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0.3;
    }
}

.NotePadContent-Wrapper {
    display: flex;
    flex-direction: column;

    .notedrop-editor-container {
        max-height: -webkit-fill-available;
        flex: 1;
        overflow: hidden;
        display: flex;
        position: relative;
        flex-direction: row;

        @media (max-width: 700px) {
            flex-direction: column;
        }
    }

    .notepad-editor {
        // @media (max-width: 500px) {
        //     max-width: 98vw;
        // }
    }

    .notepad-editor-divider {
        @media (max-width: 500px) {
            display: none;
        }
    }

    .notepad-sidenav-small {
        display: none;

        //position: absolute;
        //bottom: -12px;
        top: 0;
        left: 0;
        right: 0;

        @media (max-width: 700px) {
            display: flex;
            z-index: 1500;
            border-bottom: 1px solid var(--fq-border-color-1);
            //box-shadow: 7px 1px 5px 1px var(--fq-border-color-1);
        }
    }

    .notepad-sidenav-large {
        display: none;
        @media (min-width: 701px) {
            display: flex;
        }
    }
}
