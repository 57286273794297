#monacodiv div,
#monacoDiffEditor div {
    font-family: monospace;
    font-size: unset;
}

#monacodiv {
    .view-line {
        padding: 4px 0px;
        height: 24px;
    }
}

.Editor-Container {
    padding-top: 4px;
}
