.Notepad-Container {
    display: flex;
    flex-direction: column;
    margin: auto;

    .main-button {
        border-radius: 10px;
        outline: none;
        border: none;
        transition: 0.3s;
        cursor: pointer;

        @media (max-width: 500px) {
            width: 100%;
        }
    }

    .button-wrapper {
        max-width: 500px;

        @media (max-width: 900px) {
            max-width: 100%;
        }
    }
}

.title-text-width-limit {
    max-width: 500px;

    @media (max-width: 900px) {
        max-width: 100%;
    }
}

.notepad-home {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left !important;
    max-width: var(--fq-header-max-width);
    margin: auto;
    margin-bottom: 64px;
    padding: 0px;

    @media (max-width: 1250px) {
        padding: 0 32px;
    }

    @media (max-width: 900px) {
        text-align: center !important;
    }

    .clip-memo-input {
        background: var(--fq-fetch-note-input-background);
        outline: none;
        border: none;
        border-radius: 10px;
        box-shadow: 0px 1px 2px 1px #00000036;
        padding: 16px 24px;
        max-width: 99%;
        font-size: 2.5rem;
        color: var(--fq-primary-text-color);
        font-family: var(--fq-font-family);
        &::placeholder {
            color: var(--fq-text-placeholder-color);
        }

        @media (max-width: 1200px) {
            font-size: 2.5rem;
        }

        @media (max-width: 600px) {
            font-size: 2rem;
        }

        @media (max-width: 500px) {
            width: 100%;
            padding: 13px 24px;
            font-size: 1.2rem;
        }
    }

    .retrieve-note {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @media (max-width: 900px) {
            justify-content: center;
            align-items: center;
            margin-top: 64px;
        }

        @media (max-width: 500px) {
            h6 {
                width: 100%;
                text-align: left !important;
            }
        }

        justify-content: flex-start;
        align-items: flex-start;
    }

    .retrieve-button {
        margin-top: 24px;
        border: 1px solid #0a0a0a25;
        color: var(--fq-primary-text-color);
        background: var(--fq-disabled-background-button);

        &:disabled {
            color: var(--fq-disabled-color-button);
            background-color: var(--fq-disabled-background-button);
            border: var(--fq-disabled-border-button);
        }
    }
}
